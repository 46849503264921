import React, { FC } from 'react';

import { EvStep, EvTagsInput, EvTitle, Ttag } from '@evinced-private/ui-common';

import { isValidEmail } from '../../common/helpers/InviteUsersHelper';

import './Steps.scss';

const instructions = 'Enter up to 10 email addresses, separated by commas.';
const inputPlaceHolder = 'Add valid email address';
const invalidInputMsg = 'Value must be of email format';
const maxNumberOfTags = 10;
const delimiter = ',';

interface IInviteUsers {
    handleChange: (res: { tags: Ttag[]; error: boolean }) => void;
    isNextEnabled: boolean;
	isLastStep: boolean;
    currentEmailslist: Ttag[];
    onContinue: () => void;
}

const selectionTitle = 'Email';

const InviteUsers: FC<IInviteUsers> = ({
    isLastStep,
    handleChange,
    isNextEnabled,
    currentEmailslist,
    onContinue
}) => {
    const continueForLastStep = isLastStep ? { continueLabel: 'Send Invite(s)', onNextClicked: onContinue } : {};
    return (
        <EvStep
			actions={{ isNextEnabled, continueBtnStickToRight: true, ...continueForLastStep }}
		>
            <div className="email-tags-wrapper">
                <EvTitle titleText={selectionTitle} className="step-title" />
                <EvTagsInput
                    delimiter={delimiter}
                    initialTagValues={currentEmailslist}
                    instructionsMsg={instructions}
                    inputPlaceholderMsg={inputPlaceHolder}
                    invalidInputMsg={invalidInputMsg}
                    maxNumberOfTags={maxNumberOfTags}
                    inputValidationMethod={isValidEmail}
                    shouldHandleChangeOnBlur={true}
                    handleChange={handleChange}
                />
            </div>
        </EvStep>
    );
};

export default InviteUsers;
