import logger from '../Logger';

declare global {
	interface Window {
		_hsq: [];
		jQuery: object;
	}
}

export const hubspotAPIKey = process.env.HUBSPOT_API_KEY;

function initHubspotClient(): void {
	// Hubspot forms use jQuery internally and fail all their callbacks if no jQuery is found.
	// If we want to repond to a hubspot form submission we need to stub these methods.
	// https://community.hubspot.com/t5/APIs-Integrations/Form-callback-throws-unrelated-jquery-error/m-p/232121
	window.jQuery =
		window.jQuery ||
		(() => ({
			change: () => null,
			trigger: () => null
		}));
	if (process.env.RUN_ENV === 'development') {
		console.log('Hubspot should not run on dev');
		return;
	}

	const hubspotScript = document.createElement('script');
	hubspotScript.setAttribute('type', 'text/javascript');
	hubspotScript.setAttribute('id', 'hs-script-loader');
	hubspotScript.setAttribute('src', `//js.hs-scripts.com/${hubspotAPIKey}.js`);
	hubspotScript.async = true;
	hubspotScript.defer = true;
	hubspotScript.onload = () => {
		logger.debug('hubspot service was initialized');
	};
	hubspotScript.onerror = (err) => {
		logger.error('Could not initialize hubspot service. Got error:', err);
	};
	document.head.appendChild(hubspotScript);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getHubspotClient(): any[] {
	// copy paste from hubspot code -
	// https://developers.hubspot.com/docs/api/events/tracking-code
	// eslint-disable-next-line no-multi-assign
	const client = (window._hsq = window._hsq || []);
	return client;
}

function identifyUserByEmail(email: string): void {
	const client = getHubspotClient();
	client.push(['identify', { email }]);
}

function trackLogin(): void {
	const client = getHubspotClient();
	client.push(['trackEvent', { id: 'login' }]);
}

function sendPageView(url: string): void {
	const client = getHubspotClient();
	client.push(['setPath', url]);
	client.push(['trackPageView']);
}

export default {
	initHubspotClient,
	trackLogin,
	identifyUserByEmail,
	sendPageView
};
