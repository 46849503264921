import React, { FC } from 'react';

import { EvSelect, OptionType, SomeItemsSelectedTextFunction } from '@evinced-private/ui-common';

export enum FILTER_TYPES {
	ACTIVE = 'active',
	USER_TYPE = 'userType'
}

interface ISelectFilterProps {
	filterMultiselectId: string;
	options: OptionType[];
	values: OptionType[];
	placeHolder: string;
	filterType: FILTER_TYPES;
	someItemsSelectedText: SomeItemsSelectedTextFunction;
	allSelectedPlaceHolder?: string;
	onFilterChange: (filters: OptionType[], filterType: string) => void;
	withMultilineOptions?: boolean;
}

const SelectFilter: FC<ISelectFilterProps> = ({
	filterMultiselectId,
	options,
	values,
	placeHolder,
	filterType,
	someItemsSelectedText,
	allSelectedPlaceHolder = 'All Options Selected',
	onFilterChange,
	withMultilineOptions = false
}) => {
	const className = `select-filter ${filterMultiselectId}-select-filter`;

	return (
		<div className={className}>
			<EvSelect
				id={`${filterMultiselectId}-select`}
				options={options}
				isMulti={true}
				onChange={(filters) => {
					onFilterChange(filters, filterType);
				}}
				allowSelectAll={true}
				isSearchable={true}
				value={values || []}
				placeholder={placeHolder}
				allSelectedPlaceholder={allSelectedPlaceHolder}
				someItemsSelectedText={someItemsSelectedText}
				withMultilineOptions={withMultilineOptions}
			/>
		</div>
	);
};

export default SelectFilter;
