import { CommonLogger, LogLevel } from '@evinced-private/ui-common';

const remoteLogLevelConfig = process.env.SERVER_LOG_LEVEL;
const consoleLogLevelConfig = process.env.CONSOLE_LOG_LEVEL;
const papertrailToken = process.env.LOGS_PAPERTRAIL_TOKEN;
const remoteLogLevelThreshold = LogLevel[remoteLogLevelConfig as keyof typeof LogLevel];
const consoleLogLevelThreshold = LogLevel[consoleLogLevelConfig as keyof typeof LogLevel];
const hostname = window.location.host;
const productName = 'product-hub';

const logger = new CommonLogger();

logger.init({
	papertrailToken,
	remoteLogLevelThreshold,
	consoleLogLevelThreshold,
	productName,
	hostname
});

export default logger;
