import moment from 'moment';

export function formatDate(dateString): string {
	const utcTime = moment.utc(dateString);
	const localTime = utcTime.local();
	return localTime.format('MMM D, YYYY');
}

export default {
	formatDate
};
