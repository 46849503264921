import API_URL_PATHS from './server/ApiUrlPaths';
import ServerApi from './server/ServerApi';

const { DOWNLOAD_LINKS_URL_PATH } = API_URL_PATHS;

type SignedUrl = {
	url: string;
};

type DownloadLink = {
	downloadLink: string;
	type: string;
};

async function getMacDownloadLink(): Promise<SignedUrl> {
	return ServerApi.apiCall('GET', `${DOWNLOAD_LINKS_URL_PATH}/mobile/desktop?operatingSystem=mac`);
}

async function getWindowsDownloadLink(): Promise<SignedUrl> {
	return ServerApi.apiCall(
		'GET',
		`${DOWNLOAD_LINKS_URL_PATH}/mobile/desktop?operatingSystem=windows`
	);
}

async function getFlowAnalyzerForWebStoreLink(): Promise<DownloadLink> {
	return ServerApi.apiCall('GET', `${DOWNLOAD_LINKS_URL_PATH}/web/flow-analyzer`);
}

async function getDevDebuggerStoreLink(): Promise<DownloadLink> {
	return ServerApi.apiCall('GET', `${DOWNLOAD_LINKS_URL_PATH}/web/dev-debugger`);
}
export default {
	getMacDownloadLink,
	getWindowsDownloadLink,
	getFlowAnalyzerForWebStoreLink,
	getDevDebuggerStoreLink
};
