const GA_CATEGORIES = {
	APPLICATIONS: 'Applications'
};

const GA_ACTIONS = {
	DOWNLOAD: 'Download'
};

export default {
	GA_CATEGORIES,
	GA_ACTIONS
};
