import React, { FC, useState } from 'react';

import { EvButton, TOAST_STATUS } from '@evinced-private/ui-common';

import { PRODUCT_HUB_APP_ID } from '../../consts/dom-consts';
import { useNotifications } from '../../providers/notificationsProvider/NotificationsConsumer';
import { INotificationsContext } from '../../providers/notificationsProvider/NotificationsContext';
import { useUserTenant } from '../../providers/userTenantProvider/UserTenantProvider';
import { IServerErrors } from '../../services/server/ServerError';
import CreateUserInvitationPopup from '../create-user-invitation-popup/CreateUserInvitationPopup';
import InvitationFailedMessage from '../invitations-failed-message/InvitationFailedMessage';
import UserInvitationPopup from '../user-invitation-popup/UserInvitationPopup';

type InvitationPopupState = {
	isOpened: boolean;
	invitationId: string;
};

interface IUsersInvitationsProps {
	onAfterBulkInvitationAction: () => void;
	invitationPopupState?: InvitationPopupState;
	setInvitationPopupState?: (InvitationPopupState) => void;
}

const inviteActionButtonTitle = 'Invite Users';

const UsersInvitations: FC<IUsersInvitationsProps> = ({
	onAfterBulkInvitationAction,
	invitationPopupState,
	setInvitationPopupState
}) => {
	const [isCreateInvitationPopupOpen, setCreateInvitationPopupOpen] = useState<boolean>(false);

	const { tenant } = useUserTenant();
	const notificationsContext: INotificationsContext = useNotifications();

	const setBulkInvitationSentStatus = (
		isFailed: boolean,
		announcementMsg: string,
		serverErrors?: IServerErrors[],
		successRate?: number
	): void => {
		if (isFailed) {
			const onConfirm = (closeModal): void => closeModal();
			const promptMessage = (
				<InvitationFailedMessage serverErrors={serverErrors} successRate={successRate} />
			);
			notificationsContext.confirm({
				appElement: PRODUCT_HUB_APP_ID,
				title: announcementMsg,
				promptMessage,
				withCancelButton: false,
				approveButtonText: 'Got It',
				onConfirm
			});
		} else {
			notificationsContext.toast({
				show: true,
				status: TOAST_STATUS.SUCCESS,
				announcement: announcementMsg
			});
		}
	};

	const onAfterBulkInvitation = async (
		isFailed: boolean,
		announcementMsg: string,
		serverErrors?: IServerErrors[],
		successRate?: number
	): Promise<void> => {
		await onAfterBulkInvitationAction();
		setCreateInvitationPopupOpen(false);
		setBulkInvitationSentStatus(isFailed, announcementMsg, serverErrors, successRate);
	};
	return (
		<div className="users-invitations">
			<div className="page-actions">
				<EvButton
					title={inviteActionButtonTitle}
					onClick={() => {
						setCreateInvitationPopupOpen(true);
						notificationsContext.toast({
							show: false
						});
					}}
				>
					Invite Users
				</EvButton>
			</div>
			<CreateUserInvitationPopup
				isOpen={isCreateInvitationPopupOpen}
				onAfterBulkInvitation={onAfterBulkInvitation}
				onClose={() => {
					setCreateInvitationPopupOpen(false);
				}}
				tenantId={tenant.id}
			/>
			<UserInvitationPopup
				isOpen={invitationPopupState?.isOpened}
				onClose={() => setInvitationPopupState(null)}
				invitationId={invitationPopupState?.invitationId}
				tenantId={tenant.id}
			/>
		</div>
	);
};

export default UsersInvitations;
