import { createUrlWithFiltersAndPaginationParams, IFilterOptions } from '../helpers/FiltersHelper';
import { PaginationParams } from '../types/PaginationParams';
import { IUserToTenant, RoleTypeItem } from '../types/User';

import API_URL_PATHS from './server/ApiUrlPaths';
import ServerApi from './server/ServerApi';

const { USERS_URL_PATH, ROLES_URL_PATH } = API_URL_PATHS;

type UserToTenantResponse = {
	list: IUserToTenant[];
	overallTotal: number;
	pagination: {
		pageNumber: number;
		pageSize: number;
	};
	totalResults: number;
};

export async function getAllUsers(
	filters: IFilterOptions,
	filterOptions: IFilterOptions,
	paginationParams: PaginationParams
): Promise<UserToTenantResponse> {
	const url = `${USERS_URL_PATH}${createUrlWithFiltersAndPaginationParams(
		filters,
		filterOptions,
		paginationParams
	)}`;
	const response = await ServerApi.apiCall('GET', url);
	response.list = response.list.map((userToTenant: IUserToTenant) => ({
		...userToTenant,
		lastLogin: userToTenant.user.lastLogin
	}));
	return response;
}

export async function getRolesList(): Promise<RoleTypeItem[]> {
	const response = await ServerApi.apiCall('GET', ROLES_URL_PATH);
	return response;
}
