import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';

import RoutesHelper from '../../helpers/RoutesHelper';
import AuthenticationService from '../../services/AuthenticationService';

const AuthenticationChecker: FC = () => {
	const history = useHistory();
	const [isFocused, setFocused] = useState(false);
	const MIN_CHECK_INTERVAL_IN_MILIS = 10000;
	// need to use ref here becuase it's used on a window EventHandler
	const isCheckRunning = useRef(false);
	const lastRunningTime = useRef(null);
	const checkToken = useCallback(async (): Promise<void> => {
		try {
			isCheckRunning.current = true;
			lastRunningTime.current = new Date();
			const authResponse = await AuthenticationService.checkAndRevalidateSession();
			if (!authResponse) {
				AuthenticationService.logout();
				return;
			}
			if (authResponse.didUserSwitchAccounts) {
				// in case the user switched accounts redirect to home page
				history.push(RoutesHelper.getHomepagePath());
				// force refresh page
				history.go(0);
			}
		} catch {
			// in there was an error, logout
			AuthenticationService.logout();
		} finally {
			isCheckRunning.current = false;
			setFocused(false);
		}
	}, [history]);

	const isEnoughTimePassedSinceLastRun = (): boolean => {
		if (!lastRunningTime.current) {
			// first run - there's no previous running time
			return true;
		}
		return new Date().getTime() - lastRunningTime.current.getTime() > MIN_CHECK_INTERVAL_IN_MILIS;
	};

	// Call Auth0 only when the tab is the active one to avoid a case of multiple site scanner tabs
	// calling it at once in parallel.
	// Can happen for example when using DevServer and multiple site scanners are refreshed at once.
	const isTabVisible = (): boolean => {
		return document.visibilityState === 'visible';
	};

	useEffect(() => {
		// using `isMounted` flag here to avoid the useEffect
		// trying to update the state after component is unmounted
		let isMounted = true;
		/**
		 * There are several mechanisms to prevent us from sending too many requests to Auth0.
		 * 1. A lock that enables only one request to be sent - isCheckRunning
		 *    When multiple requests are sent in parallel we get 'timeout' error from Auth0.
		 * 2. A minimum time to wait between requests
		 *    To reduce the load even in a case a user focuses in and out fast, we'll send a request
		 *    at most every X seconds.
		 * 3. Checking token only if the tab is active
		 *    In case for some reason several tabs are being focused / refreshed,
		 *    only one can be active.
		 * 	  Can happen during hot reload in dev env,
		 *    or when a user is reopening chrome with multiple tabs for our apps.
		 *    This is another defence mechanism vs. multiple calls in parallel
		 */
		if (isMounted) {
			if (
				isFocused &&
				!isCheckRunning.current &&
				isEnoughTimePassedSinceLastRun() &&
				isTabVisible()
			) {
				checkToken();
			} else {
				// check for isMounted to avoid updating the state if component is unmounted after logout
				setFocused(false);
			}
		}
		return () => {
			isMounted = false;
		};
	}, [isFocused, checkToken]);

	const windowFocused = (): void => {
		setFocused(true);
	};

	useEffect(() => {
		window.addEventListener('focus', windowFocused);
		return () => window.removeEventListener('focus', windowFocused);
	}, [isFocused]);

	return null;
};

export default AuthenticationChecker;
