enum EXTERNAL_LINKS {
	// product information links from marketing site
	LEARN_MORE_MOBILE_ANALYZER_LINK = 'https://www.evinced.com/products/flow-analyzer-mobile',
	LEARN_MORE_SITE_SCANNER_LINK = 'https://www.evinced.com/products/site-scanner',
	LEARN_MORE_MOBILE_AUTOMATION_LINK = 'https://www.evinced.com/products/automation-mobile',
	LEARN_MORE_USER_FLOW_ANALYZER_LINK = 'https://www.evinced.com/products/flow-analyzer-web',
	LEARN_MORE_DEV_DEBUGGER_LINK = 'https://www.evinced.com/products/dev-debugger',
	LEARN_MORE_WEB_AUTOMATION_LINK = 'https://www.evinced.com/products/automation-web',
	LEARN_MORE_DESIGN_ANALYZER_LINK = 'https://www.evinced.com/products/design-assistant',
	LEARN_MORE_CODE_ASSISTANT_LINK = 'https://www.evinced.com/products/code-assistant',
	LEARN_MORE_UNIT_TESTER_LINK = 'https://www.evinced.com/products/unit-tester',

	// developer documentation

	// sdks
	APPIUM_SDK_DEVELOPER_DOCS_LINK = 'https://developer.evinced.com/sdks-for-mobile-apps/appium-sdk-java-doc',
	APPIUM_PYTHON_SDK_DEVELOPER_DOCS_LINK = 'https://developer.evinced.com/sdks-for-mobile-apps/appium-sdk-python-doc',
	XCUI_SDK_DEVELOPER_DOCS_LINK = 'https://developer.evinced.com/sdks-for-mobile-apps/xcuitest-sdk',
	WEBDRIVERIO_MOBILE_SDK_DEVELOPER_DOCS_LINK = 'https://developer.evinced.com/sdks-for-mobile-apps/wdio-mobile-sdk',
	ESPRESSO_SDK_DEVELOPER_DOCS_LINK = 'https://developer.evinced.com/sdks-for-mobile-apps/espresso-sdk',
	CYPRESS_SDK_DEVELOPER_DOCS_LINK = 'https://developer.evinced.com/sdks-for-web-apps/cypress-sdk',
	SELENIUM_CS_SDK_DEVELOPER_DOCS_LINK = 'https://developer.evinced.com/sdks-for-web-apps/cs-selenium-sdk',
	SELENIUM_JS_SDK_LINK = 'https://developer.evinced.com/sdks-for-web-apps/selenium-js-sdk',
	SELENIUM_SDK_DEVELOPER_DOCS_LINK = 'https://developer.evinced.com/sdks-for-web-apps/selenium-java-sdk',
	WEBDRIVERIO_SDK_DEVELOPER_DOCS_LINK = 'https://developer.evinced.com/sdks-for-web-apps/webdriverio-sdk',
	PLAYWRIGHT_JAVA_SDK_LINK = 'https://developer.evinced.com/sdks-for-web-apps/playwright-java-sdk',
	PLAYWRIGHT_JS_SDK_LINK = 'https://developer.evinced.com/sdks-for-web-apps/playwright-js-sdk',
	TESTCAFE_SDK_LINK = 'https://developer.evinced.com/sdks-for-web-apps/testcafe-js-sdk',

	// unit tester
	UNIT_TESTER_LINK = 'https://developer.evinced.com/sdks-for-web-apps/unit-tester',
	DESIGN_ANALYZER_DOWNLOAD_LINK = 'https://storage.googleapis.com/figma-plugin/design-assistant-latest.zip'
}

export default EXTERNAL_LINKS;
