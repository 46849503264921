import React, { FC, useCallback, useEffect, useState } from 'react';

import FullPageError from '../../components/full-page-error/FullPageError';
import { getErrorMessage } from '../../helpers/ApiErrorHelper';
import Logger from '../../services/Logger';
import { activateProduct, getUserTenant, hasAccessToProduct } from '../../services/TenantsService';
import { ProductType } from '../../types/Tenant';

interface IProductActivationLoaderProps {
	productType: ProductType;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	children: any; // children will be rendered after activation is verified
}
const ProductActivationLoader: FC<IProductActivationLoaderProps> = ({
	productType,
	children
}: IProductActivationLoaderProps) => {
	const [isLoading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const activateProductIfNeeded = useCallback(async (): Promise<void> => {
		if (hasAccessToProduct(productType)) {
			setLoading(false);
			return;
		}

		// need to grant access to product
		const tenant = await getUserTenant();
		if (!tenant) {
			Logger.error('No tennat was found for this user, weird, they should not go to this page');
			setError('No tenant found');
			setLoading(false);
			return;
		}

		try {
			await activateProduct(tenant.id, productType);
			Logger.debug('Product activated successfully');
			setLoading(false);
		} catch (error) {
			Logger.error('Error activating product', error);
			setError(`We're having issues with activating your account. ${getErrorMessage(error)}`);
		}
	}, [productType]);

	useEffect(() => {
		activateProductIfNeeded();
	}, [activateProductIfNeeded]);

	if (isLoading) {
		// no loader will be shown,
		// it's supposed to be a very quick process - done once, so a white screen is OK
		return null;
	}

	if (error) {
		return <FullPageError title="Account Activation Error" />;
	}

	return children;
};

export default ProductActivationLoader;
