import React, { FC, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import {
	BUTTON_TYPES,
	EvButton,
	EvCard,
	EvIcon,
	EvSpinner,
	EvTextInput,
	useTitle
} from '@evinced-private/ui-common';

import AppHelper from 'src/helpers/AppHelper';

import { ContactSupportMailLink } from '../../components/contact-support/ContactSupportMailLink';
import FullPageError from '../../components/full-page-error/FullPageError';
import InfoIcon from '../../components/icons/NewInfoIcon.svg';
import { getErrorMessage } from '../../helpers/ApiErrorHelper';
import InvitationTokenHelper, {
	PRODUCT_TO_REDIRECT_PARAM
} from '../../helpers/InvitationTokenHelper';
import RoutesHelper from '../../helpers/RoutesHelper';
import Logger from '../../services/Logger';
import {
	createTenant,
	getTenantDescriptor,
	getUserTenant,
	TenantDescriptorValue,
	TenantPlan
} from '../../services/TenantsService';
import { ITenantDescriptor } from '../../types/TenantDescriptor';

import './TeanantCreationPage.scss';

const titleText = 'Welcome aboard!';
const descriptionText = 'Enter your company name to set up your account.';
const labelText = 'Company name';
const buttonText = 'Create Company Account';
const infoTextBegining = 'If your company is already working with Evinced, please ';
const infoTextContinue =
	" or your Evinced admin and we will connect you with the correct account. Otherwise, your company's license won't be applied for your user.";

const TenantCreationPage: FC = () => {
	const [accountName, setAccountName] = useState<string>('');
	const [isLoading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [isSaveInProgress, setSaveInProgress] = useState(false);
	const [accountPlan, setAccountPlan] = useState<TenantPlan>(TenantPlan.FREE_TRIAL);

	const history = useHistory();
	const { setTitle } = useTitle();
	setTitle(AppHelper.getPageTitle(''), 'Tenant Creation');

	const validateNoTenantExists = useCallback(async (): Promise<void> => {
		const tenant = await getUserTenant();
		// show create tenant dialog
		if (!tenant) {
			setLoading(false);
			return;
		}

		// there's a tenant with the right permissions,
		// not sure how we got here, but we should redirect the user to the homepage
		history.push(RoutesHelper.getHomepagePath());
	}, [history]);

	const initTenantAccountPlan = useCallback(async (): Promise<void> => {
		try {
			const tenantDescriptor: ITenantDescriptor = await getTenantDescriptor();
			let accountPlan = null;
			if (tenantDescriptor?.accountPlan?.values.includes(TenantDescriptorValue.COMMUNITY)) {
				accountPlan = TenantPlan.COMMUNITY;
			} else {
				accountPlan = TenantPlan.FREE_TRIAL;
			}
			setAccountPlan(accountPlan);
		} catch (err) {
			Logger.error('Error getting user data', err);
		}
		setLoading(false);
	}, []);

	const initPage = useCallback(async (): Promise<void> => {
		await validateNoTenantExists();
		await initTenantAccountPlan();
	}, [validateNoTenantExists, initTenantAccountPlan]);

	useEffect(() => {
		initPage();
	}, [initPage]);

	const onCreateTenantClicked = async (): Promise<void> => {
		setSaveInProgress(true);
		try {
			const tenant = await createTenant(accountName, accountName, accountPlan);
			Logger.debug('Tenant created successfully', { tenant });
			const redirectionUrlParam = InvitationTokenHelper.getProductToRedirectFromUrl();
			let redirectLink = RoutesHelper.getHomepagePath();
			if (redirectionUrlParam) {
				redirectLink += `?${PRODUCT_TO_REDIRECT_PARAM}=${redirectionUrlParam}`;
			}
			history.push(redirectLink);
		} catch (error) {
			Logger.error('Error creating a tenant', error);
			setError(getErrorMessage(error));
			setSaveInProgress(false);
		}
	};

	const isFormReady = (): boolean => {
		return !!accountName;
	};

	const getFooterButton = (): JSX.Element => {
		return (
			<EvButton
				type={BUTTON_TYPES.PRIMARY}
				onClick={onCreateTenantClicked}
				disabled={!isFormReady()}
				loading={isSaveInProgress}
				title={buttonText}
				className="create-tenant-button"
			>
				{buttonText}
			</EvButton>
		);
	};

	const renderInfoText = (): JSX.Element => (
		<p className="info-text">
			<span>{infoTextBegining}</span>
			<ContactSupportMailLink linkText="contact us" />
			<span>{infoTextContinue}</span>
		</p>
	);

	if (isLoading) {
		return (
			<div className="tenant-creation-page">
				<EvSpinner />
			</div>
		);
	}
	if (error) {
		return <FullPageError title="Account Creation Error" />;
	}
	return (
		<div className="tenant-creation-page">
			<EvCard
				title={titleText}
				footer={getFooterButton()}
				className="tenant-creation-card"
				headingLevel={1}
			>
				<>
					<div className="creation-form">
						<p className="description">{descriptionText}</p>
						<label id="company-name-label" className="input-label">
							{labelText}
						</label>
						<EvTextInput
							className="form-input"
							ariaLabelledBy="company-name-label"
							value={accountName}
							onChange={setAccountName}
							isRequired={true}
						/>
					</div>
					<div className="info">
						<EvIcon className="info-icon" icon={InfoIcon} small />
						{renderInfoText()}
					</div>
				</>
			</EvCard>
		</div>
	);
};
export default TenantCreationPage;
