const GA_LOCAL_STORAGE_KEY = 'GOOGLE_ANALYTICS_INFO';
const ACCESS_TOKEN_NAME = 'evincedAccessToken';
const ID_TOKEN_NAME = 'evincedIdToken';

function getFromLocalStorage(key: string): string {
	return localStorage.getItem(key);
}

function saveToLocalStorage(key: string, value): void {
	localStorage.setItem(key, value);
}

function deleteFromLocalStorage(key: string): void {
	localStorage.removeItem(key);
}

function getUserFromLocalStorage(): { userId: string } {
	const gaUser = localStorage.getItem(GA_LOCAL_STORAGE_KEY);
	if (gaUser) {
		return JSON.parse(gaUser);
	}
	return null;
}

function setGoogleAnalyticsUser(user: { userId: string }): void {
	localStorage.setItem(GA_LOCAL_STORAGE_KEY, JSON.stringify(user));
}

function getAccessToken(): string {
	return localStorage.getItem(ACCESS_TOKEN_NAME);
}

function setAccessToken(token): void {
	localStorage.setItem(ACCESS_TOKEN_NAME, token);
}

function getIdToken(): string {
	return localStorage.getItem(ID_TOKEN_NAME);
}

function setIdToken(token): void {
	localStorage.setItem(ID_TOKEN_NAME, token);
}

function removeAuthenticationTokens(): void {
	localStorage.removeItem(ACCESS_TOKEN_NAME);
	localStorage.removeItem(ID_TOKEN_NAME);
}

function removeValuesOnLogout(): void {
	removeAuthenticationTokens();
	localStorage.removeItem(GA_LOCAL_STORAGE_KEY);
}

export default {
	getFromLocalStorage,
	saveToLocalStorage,
	deleteFromLocalStorage,
	getUserFromLocalStorage,
	setGoogleAnalyticsUser,
	getAccessToken,
	setAccessToken,
	getIdToken,
	setIdToken,
	removeAuthenticationTokens,
	removeValuesOnLogout
};
