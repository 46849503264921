export const TOKEN_QUERY_PARAM = 'invitation_token';
export const EMAIL_QUERY_PARAM = 'email';
export const PRODUCT_TO_REDIRECT_PARAM = 'redirect_to_product';
const REFERRER_SOURCE = 'referrer';
const WEBSITE_LOGIN_BUTTON_REFERRER_VALUE = 'website-login';

export enum REDIRECT_TO_PRODUCT_VALUE {
	MOBILE_SDK = 'mobile_sdk',
	MOBILE_FLOW_ANALYZER = 'mobile_flow_analyzer'
}
const getQueryParams = (): URLSearchParams => {
	return new URLSearchParams(window.location.search);
};
const getHashParams = (): URLSearchParams => {
	return new URLSearchParams(window.location.hash);
};

const getQueryParam = (paramName: string): string => {
	return getQueryParams().get(paramName);
};

const getParamFromQueryOrHash = (paramName: string): string => {
	const tokenFromParams = getQueryParam(paramName);
	if (tokenFromParams) {
		return tokenFromParams;
	}
	// if the URL is redirected from Auth0 signup activation page,
	// the param will be in the hash (after #)
	return getHashParams().get(paramName);
};

const getInvitationTokenFromUrl = (): string => {
	return getParamFromQueryOrHash(TOKEN_QUERY_PARAM);
};

const getEmailFromUrl = (): string => {
	return getParamFromQueryOrHash(EMAIL_QUERY_PARAM);
};

const getProductToRedirectFromUrl = (): REDIRECT_TO_PRODUCT_VALUE => {
	const redirectionValue = getParamFromQueryOrHash(PRODUCT_TO_REDIRECT_PARAM);
	return redirectionValue as REDIRECT_TO_PRODUCT_VALUE;
};

const shouldHideSignupOption = (): boolean => {
	const origin = getQueryParam(REFERRER_SOURCE);
	return origin === WEBSITE_LOGIN_BUTTON_REFERRER_VALUE;
};

export default {
	getInvitationTokenFromUrl,
	getEmailFromUrl,
	getProductToRedirectFromUrl,
	shouldHideSignupOption,
	TOKEN_QUERY_PARAM,
	EMAIL_QUERY_PARAM
};
