import React, { FC } from 'react';

import { EvContactSupportEmailLink } from '@evinced-private/ui-common';

import './ContactSupportMailLink.scss';

interface IContactSupportMailLinkProps {
	linkText?: string;
}
export const ContactSupportMailLink: FC<IContactSupportMailLinkProps> = ({ linkText }) => {
	return <EvContactSupportEmailLink linkText={linkText} className="product-hub-contact-us-link" />;
};
