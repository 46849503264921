const API_URL_PATHS = {
	DOWNLOAD_LINKS_URL_PATH: '/applications',
	USER_URL_PATH: '/user',
	USERS_URL_PATH: '/user-to-tenants',
	DESCRIPTORS_URL_PATH: '/descriptors',
	TENANTS_URL_PATH: '/tenants',
	SERVICE_ACCOUNTS_URL_PATH: '/service-accounts',
	USER_INVITATIONS_URL_PATH: '/user-invitations',
	OPEN_INVITATIONS_URL_PATH: '/open-invitations',
	PRODUCT_PLANS_URL_PATH: '/product-plans',
	ROLES_URL_PATH: '/roles'
};

export default API_URL_PATHS;
