import RoutesHelper from './RoutesHelper';

function getRedirectToUrl(pathname: string, userHasPendingInvitations: boolean): string {
	// when there's no tenant and users comes from the open invitation link,
	// take them to the open invitation activation page
	if (
		(userHasPendingInvitations && pathname !== RoutesHelper.getTenantCreationPage()) ||
		pathname.includes(RoutesHelper.getInvitationActivationPage())
	) {
		return RoutesHelper.getInvitationActivationPage();
	}
	if (!userHasPendingInvitations) {
		return RoutesHelper.getNoTenantPath();
	}
	return RoutesHelper.getTenantCreationPage();
}

export default { getRedirectToUrl };
