import { IServerError } from '../services/server/ServerError';

export const getErrorMessage = (serverError: IServerError): string | null => {
	return serverError?.error?.message || `Response status ${serverError?.response?.status}`;
};

/**
 * returns true if the error code is 4XX
 * @param serverError
 */
export const isClientError = (serverError: IServerError): boolean => {
	const status = serverError?.response?.status;
	return status < 500 && status >= 400;
};
