import { IOpenInvitation } from './OpenInvitation';
import { IUserInvitation } from './UserInvitation';

export enum INVITATION_TYPE {
	OPEN,
	EMAIL
}
export type PendingInvitation = {
	type: INVITATION_TYPE;
	invitation: IUserInvitation | IOpenInvitation;
	createdTime: Date;
};
