import ServerApi from './ServerApi';

const responsesCache = {};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function callGETAndCache(url: string): Promise<any> {
	if (!responsesCache[url]) {
		const response = await ServerApi.apiCall('GET', url);
		responsesCache[url] = response;
	}
	return responsesCache[url];
}
