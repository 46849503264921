/* eslint-disable react/no-unstable-nested-components */
import React, { FC } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import { HubspotProvider } from '@aaronhayes/react-use-hubspot-form';
import { History } from 'history';

import { MAIN_TITLE } from 'src/consts/products-consts';

import PrivateRoute from './components/auth/PrivateRoute';
import AuthenticationChecker from './components/authentication-checker/AuthenticationChecker';
import Header from './components/header/Header';
import DeviceHelper from './helpers/DeviceHelper';
import RoutesHelper from './helpers/RoutesHelper';
import AuthToken from './pages/authentication/AuthToken';
import LoginPage from './pages/authentication/LoginPage';
import SignupPage from './pages/authentication/SignupPage';
import CodeAssistantPage from './pages/code-assistant-page/CodeAssistantPage';
import InvitationActivationPage from './pages/invitation-activation/InvitationActivationPage';
import EmailInvitationRedirectionPage from './pages/invitation-redirection/EmailInvitationRedirectionPage';
import ManageUsersRouter from './pages/manage-users-tabs-view/ManageUsersRouter';
import ManageUsersTabsView from './pages/manage-users-tabs-view/ManageUsersTabsView';
import MobileFlowAnalyzerPage from './pages/mobile-flow-analyzer-page/MobileFlowAnalyzerPage';
import MobileAutomationSDKPage from './pages/mobile-sdk-page/MobileSDKPage';
import NoTenantPage from './pages/no-tenant/NoTenantPage';
import NotFound404Page from './pages/not-found-404-page/NotFound404Page';
import NotSupportedScreenSizePage from './pages/not-supported-screen-size/NotSupportedScreenSizePage';
import OpenInvitationRedirectionPage from './pages/open-invitation-redirection/OpenInvitationRedirectionPage';
import ProductActivationLoader from './pages/product-activation-loader/ProductActivationLoader';
import TenantCreationPage from './pages/tenant-creation/TenantCreationPage';
import Unauthorized403Page from './pages/unauthorized-403-page/Unauthorized403Page';
import WebSDKPage from './pages/web-sdk-page/WebSdkPage';
import WelcomePage from './pages/welcome-page/WelcomePage';
import { NavigationProvider } from './providers/navigationProvider/NavigationProvider';
import NotificationsProvider from './providers/notificationsProvider/NotificationsProvider';
import { TableStateProvider } from './providers/tableStateProvider/TableStateProvider';
import { UserTenantProvider } from './providers/userTenantProvider/UserTenantProvider';
import { ProductType } from './types/Tenant';

import './App.scss';

type IApp = {
	history: History;
};

const App: FC<IApp> = ({ history }) => {
	if (!DeviceHelper.isDeviceSupported()) {
		return <NotSupportedScreenSizePage />;
	}
	const isAuthenticationRequired = (pathname: string): boolean => {
		const unauthenticatedRoutes = [
			RoutesHelper.getSignupPath(),
			RoutesHelper.getLoginPath(),
			RoutesHelper.getInvitationRedirectionPath()
		];
		const isUnauthenticatedPage = unauthenticatedRoutes.some((path) => {
			return pathname.startsWith(path);
		});
		return !isUnauthenticatedPage;
	};
	return (
		<UserTenantProvider>
			<NotificationsProvider>
				<NavigationProvider>
					<TableStateProvider state={{}}>
						<HubspotProvider>
							<Router history={history}>
								<div className="app">
									<Route
										render={({ location }) => {
											return isAuthenticationRequired(location.pathname) ? (
												<AuthenticationChecker />
											) : null;
										}}
									/>
									<Route
										render={({ location }) => {
											// don't show the header on unauthenticated screens
											const isAuthRequired = isAuthenticationRequired(location.pathname);
											return isAuthRequired ? <Header isAuthenticatedPage={true} /> : null;
										}}
									/>

									<main className="main-section" aria-labelledby={MAIN_TITLE}>
										<Switch>
											<Route
												exact
												path={RoutesHelper.getInvitationRedirectionPath()}
												component={EmailInvitationRedirectionPage}
											/>
											<Route
												exact
												path={RoutesHelper.getOpenInvitationRedirectionPath()}
												component={OpenInvitationRedirectionPage}
											/>
											<Route exact path={RoutesHelper.getLoginPath()} component={LoginPage} />
											<Route exact path={RoutesHelper.getSignupPath()} component={SignupPage} />
											<Route
												exact
												path={RoutesHelper.getLoginSuccessPath()}
												component={AuthToken}
											/>
											<PrivateRoute
												exact
												path={RoutesHelper.getHomepagePath()}
												component={() => <WelcomePage />}
											/>
											<PrivateRoute
												exact
												path={RoutesHelper.getFlowAnalyzerForMobilePath()}
												component={() => (
													<ProductActivationLoader productType={ProductType.MOBILE_FLOW_ANALYZER}>
														<MobileFlowAnalyzerPage />
													</ProductActivationLoader>
												)}
											/>
											<PrivateRoute
												exact
												path={RoutesHelper.getMobileSDKPath()}
												component={() => (
													<ProductActivationLoader productType={ProductType.MOBILE_SDK}>
														<MobileAutomationSDKPage />
													</ProductActivationLoader>
												)}
											/>
											<PrivateRoute
												exact
												path={RoutesHelper.getWebSDKPath()}
												component={() => (
													<ProductActivationLoader productType={ProductType.WEB_SDK}>
														<WebSDKPage />
													</ProductActivationLoader>
												)}
											/>

											<Route
												exact
												path={RoutesHelper.getInvitationsPagePath()}
												component={() => (
													<Redirect
														to={{
															pathname: RoutesHelper.getUserManagementPagePath()
														}}
													/>
												)}
											/>
											<PrivateRoute
												exact
												path={RoutesHelper.getUserManagementPagePath()}
												component={ManageUsersTabsView}
											/>
											<PrivateRoute
												exact
												path={RoutesHelper.getInvitationActivationPage()}
												component={InvitationActivationPage}
											/>
											<PrivateRoute
												path={RoutesHelper.getManageUserTabsViewPagePath()}
												component={ManageUsersRouter}
											/>
											<PrivateRoute
												exact
												path={RoutesHelper.getTenantCreationPage()}
												component={TenantCreationPage}
											/>
											<PrivateRoute
												exact
												path={RoutesHelper.getNoTenantPath()}
												component={NoTenantPage}
											/>
											<PrivateRoute
												exact
												path={RoutesHelper.getCodeAssistantPage()}
												component={CodeAssistantPage}
											/>
											<PrivateRoute
												exact
												path={RoutesHelper.getUnauthorized403Page()}
												component={Unauthorized403Page}
											/>
											<PrivateRoute component={NotFound404Page} />
										</Switch>
									</main>
								</div>
							</Router>
						</HubspotProvider>
					</TableStateProvider>
				</NavigationProvider>
			</NotificationsProvider>
		</UserTenantProvider>
	);
};

export default App;
