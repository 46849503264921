import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import {
	EvMainTitle,
	EvSection,
	EvSpinner,
	TABLE_SR_SUMMARY_IDS,
	TOAST_STATUS,
	useSkipLinks,
	useTitle } from '@evinced-private/ui-common';

import AppHelper from 'src/helpers/AppHelper';

import { createHeadersOutOfTableColumns } from '../../app-tools/csv-wizard';
import DownloadCSV from '../../components/common/download-csv/DownloadCSV';
import {
	getColumnsFormatters,
	getInvitationsTableColumns
} from '../../components/common/helpers/InviteUsersHelper';
import SearchFilter from '../../components/common/search-filter/SearchFilter';
import UsersInvitations from '../../components/users-invitations/UsersInvitations';
import UsersInvitationsTable from '../../components/users-invitations-table/UsersInvitationsTable';
import withLimitedAccess from '../../hoc/withLimitedAccess';
import { useNotifications } from '../../providers/notificationsProvider/NotificationsConsumer';
import { INotificationsContext } from '../../providers/notificationsProvider/NotificationsContext';
import { useUserTenant } from '../../providers/userTenantProvider/UserTenantProvider';
import Logger from '../../services/Logger';
import userInvitationsService from '../../services/UserInvitationService';
import { IUserInvitation } from '../../types/UserInvitation';

import './UsersManagementPage.scss';

const csvFileName = `invitations-data-${Date.now()}.csv`;
const formatDateColumns = ['createdTime'];

const UsersManagementPage: FC = () => {
	const [isLoading, setLoading] = useState<boolean>(true);
	const [invitations, setInvitations] = useState<IUserInvitation[]>(null);
	const [invitationPopupState, setInvitationPopupState] = useState<{
		isOpened: boolean;
		invitationId: string;
	}>();
	const [searchText, setSearchText] = useState<string>('');
	const notificationsContext: INotificationsContext = useNotifications();
	const { hasAdminPermissions, getFullRoleItem } = useUserTenant();
  const { setTitle } = useTitle();
	const pageTitle = 'Pending Invitations';
	setTitle(AppHelper.getPageTitle(''), pageTitle);
	const { tenant } = useUserTenant();
	const getAllInvitations = useCallback(async (): Promise<void> => {
		setLoading(true);
		try {
			const invitations = await userInvitationsService.getAllInvitations(tenant.id);
			setInvitations(invitations);
		} catch (e) {
			notificationsContext.alert({ errorMessage: 'Error getting all invitations', serverError: e });
		} finally {
			setLoading(false);
		}
	}, [notificationsContext, tenant]);

	const revokeInvitation = useCallback(
		async (invitationId: string): Promise<void> => {
			try {
				await userInvitationsService.revokeInvitation(invitationId, tenant.id);
				await getAllInvitations();
				notificationsContext.toast({
					show: true,
					status: TOAST_STATUS.SUCCESS,
					announcement: 'Invitation deleted successfully'
				});
			} catch (e) {
				Logger.error('Error revoking invitation', e);
				notificationsContext.alert({ errorMessage: 'Error revoking invitation', serverError: e });
			}
		},
		[notificationsContext, tenant, getAllInvitations]
	);

	const onInputChange = useCallback((searchTextValue: string) => {
		setSearchText(searchTextValue);
	}, []);

	const renderUserManagementPageContent = useMemo(() => {
		return (
			// eslint-disable-next-line react/jsx-no-useless-fragment
			<>
				{isLoading ? (
					<EvSpinner />
				) : (
					<div>
						<div className="filter-line">
							<SearchFilter searchInputValue={searchText} onInputChange={onInputChange} />
							<DownloadCSV
								csvFileName={csvFileName}
								reportHeaders={[
									...createHeadersOutOfTableColumns(getInvitationsTableColumns(getFullRoleItem))
								]}
								sortByColumn="createdTime"
								data={invitations}
								formatters={getColumnsFormatters()}
								formatDateColumns={formatDateColumns}
							/>
						</div>

						<UsersInvitationsTable
							invitations={invitations}
							revokeInvitation={revokeInvitation}
							showInvitationPopup={(invitationId) => {
								setInvitationPopupState({
									isOpened: true,
									invitationId
								});
							}}
							filterText={searchText}
						/>
					</div>
				)}
			</>
		);
	}, [isLoading, invitations, searchText, onInputChange, revokeInvitation, getFullRoleItem]);

	useEffect(() => {
		getAllInvitations();
	}, [getAllInvitations]);

	// updating skip links when page loads
	useSkipLinks(true);

	return (
		<div className="users-management-page">
			<div className="users-management-page-title">
				<div className="title-area">
					<EvMainTitle>{pageTitle}</EvMainTitle>
				</div>
				{hasAdminPermissions() && (
					<UsersInvitations
						invitationPopupState={invitationPopupState}
						setInvitationPopupState={setInvitationPopupState}
						onAfterBulkInvitationAction={getAllInvitations}
					/>
				)}
			</div>
			<EvSection
				ariaLabel="Users invitations table"
				ariaDescribedby={TABLE_SR_SUMMARY_IDS}
				ariaLive="polite"
				skipLinkId="users-invitations-table"
			>
				{renderUserManagementPageContent}
			</EvSection>
		</div>
	);
};

export default withLimitedAccess(UsersManagementPage);
