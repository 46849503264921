import analyticsService from '../analytics/AnalyticsService';
import GoogleAnalyticsCategories from '../analytics/GoogleAnalyticsCategories';

export default class FileHelper {
	static downloadFileFromUrl(url: string, label: string): void {
		const a = document.createElement('a');
		document.body.appendChild(a);
		a.style.display = 'none';
		a.href = url;
		a.click();
		window.URL.revokeObjectURL(url);
		analyticsService.fireEvent({
			category: GoogleAnalyticsCategories.GA_CATEGORIES.APPLICATIONS,
			action: GoogleAnalyticsCategories.GA_ACTIONS.DOWNLOAD,
			label
		});
	}
}
