import React, { FC, useEffect, useState } from 'react';

import { copyTextToClipboard, EvButton, EvLink, EvTextInput } from '@evinced-private/ui-common';

import { getErrorMessage } from '../../helpers/ApiErrorHelper';
import userInvitationsService from '../../services/UserInvitationService';
import ProductHubPopup from '../common/product-hub-popup/ProductHubPopup';

import './UserInvitationPopup.scss';

interface IUserInvitationPopupProps {
	isOpen: boolean;
	invitationId: string;
	onClose: () => void;
	tenantId: string;
}

const UserInvitationPopup: FC<IUserInvitationPopupProps> = ({
	isOpen = false,
	invitationId,
	onClose,
	tenantId
}: IUserInvitationPopupProps) => {
	const [email, setEmail] = useState(null);
	const [invitationLink, setInvitationLink] = useState(null);
	const [serverError, setServerError] = useState(null);
	const [isLoading, setLoading] = useState<boolean>(true);

	const onPopupOpened = async (): Promise<void> => {
		setLoading(true);

		try {
			const invitation = await userInvitationsService.getInvitation(tenantId, invitationId);
			setEmail(invitation.targetEmail);
			setInvitationLink(invitation.url);
		} catch (error) {
			const message = getErrorMessage(error) || '';
			setServerError(message);
		} finally {
			setLoading(false);
		}
	};

	const renderPopupContent = (): JSX.Element => {
		if (serverError) {
			return <div className="errors-area">{serverError}</div>;
		}
		return (
			<div className="instructions">
				We sent an email to <EvLink linkText={email} url={`mailto:${email}`} openInNewTab={true} />{' '}
				and invited them to join your account at Evinced.
				<br />
				<br />
				They can also use this invitation link below - feel free to copy and send it to them.
				<div className="invitation-link">
					<EvTextInput
						value={invitationLink}
						isDisabled
						onChange={null}
						className="invitation-link-url"
					/>
					<EvButton
						title="Copy link to clipboard"
						onClick={() => copyTextToClipboard(invitationLink, 'Link')}
					>
						Copy
					</EvButton>
				</div>
			</div>
		);
	};

	// reset state when popup closes
	useEffect(() => {
		if (!isOpen) {
			setEmail(null);
			setInvitationLink(null);
			setServerError(null);
		}
	}, [isOpen]);

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			<ProductHubPopup
				isControlled
				isOpen={isOpen}
				isCentered
				onClose={onClose}
				onOpen={onPopupOpened}
				title="Email invitation sent"
				className="user-invitation-popup"
				isPopupLoading={isLoading}
			>
				<div className="user-invitation-popup-wrapper">
					<div className="user-invitation-popup-content">{renderPopupContent()}</div>
				</div>
			</ProductHubPopup>
		</>
	);
};

export default UserInvitationPopup;
