import RoutesHelper from '../../helpers/RoutesHelper';

export const navigationBreadcrumbsToFilter = [
	'invitation-activation',
	RoutesHelper.getUnauthorized403Page(),
	RoutesHelper.getTenantCreationPage(),
	RoutesHelper.getOpenInvitationRedirectionPath(),
	RoutesHelper.getInvitationRedirectionPath(),
	RoutesHelper.getInvitationActivationPage(),
	RoutesHelper.getSignupPath(),
	RoutesHelper.getLoginSuccessPath(),
	RoutesHelper.getLoginPath()
];

/**
 * This title mapping was created because Eyal's request to change the title of those
 * two routes but not to change the route itself.
 */
export const navigationTitleMapping = {
	'web-sdk': 'Automation for Web',
	'mobile-sdk': 'Automation for Mobile',
	'': 'All products',
};
