import React, { FC, useCallback, useEffect, useState } from 'react';

import {
	BUTTON_TYPES,
	EvButton,
	OptionType,
	TABLE_FILTERS_SR_SUMMARY
} from '@evinced-private/ui-common';

import { areFiltersEqual, IFilterOptions } from '../../helpers/FiltersHelper';
import SearchFilter from '../common/search-filter/SearchFilter';
import SelectFilter, { FILTER_TYPES } from '../select-filter/SelectFilter';

import './UsersTableFilters.scss';

interface IUsersTableFiltersProps {
	filterOptions: IFilterOptions;
	filterValues: IFilterOptions;
	onApply: (filterValues: IFilterOptions) => void;
	onClear: () => void;
	disableButtons?: boolean;
}

const UsersTableFilters: FC<IUsersTableFiltersProps> = ({
	filterOptions,
	filterValues,
	onApply,
	onClear,
	disableButtons
}) => {
	const [currentFilters, setCurrentFilters] = useState<IFilterOptions>(null);
	const [applyDisabled, setApplyDisabled] = useState<boolean>(false);
	const [clearDisabled, setClearDisabled] = useState<boolean>(false);

	const onFilterChange = (filters: OptionType[], filterType: string): void => {
		const updatedFilters = { ...currentFilters, [filterType]: filters };
		setCurrentFilters(updatedFilters);
	};

	const onInputChange = useCallback((freeSearch: string): void => {
		setCurrentFilters((prevFilters) => ({ ...prevFilters, freeSearch: freeSearch?.toLowerCase() }));
	}, []);

	const renderSrSummary = (): JSX.Element => {
		const selectedStatusOptions = filterValues?.active?.length;
		const selectedTypeOptions = filterValues?.userType?.length;
		const allStatusOptions = filterOptions?.active?.length;
		const allTypeOptions = filterOptions?.userType?.length;

		const statusText =
			selectedStatusOptions === allStatusOptions || !filterValues ? 'all' : selectedStatusOptions;
		const typesText =
			selectedTypeOptions === allTypeOptions || !filterValues ? 'all' : selectedTypeOptions;

		return (
			<div id={TABLE_FILTERS_SR_SUMMARY} className="sr-only">
				Active users table filter results:
				{`User Status: ${statusText},`}
				{`User types: ${typesText}`}
			</div>
		);
	};

	useEffect(() => {
		const areFiltersApplied = filterValues && Object.keys(filterValues)?.length > 0;
		const currentAppliedFilters = filterValues || filterOptions;
		setCurrentFilters(currentAppliedFilters);
		setClearDisabled(!areFiltersApplied);
	}, [filterOptions, filterValues]);

	useEffect(() => {
		const areFiltersChanged = areFiltersEqual(filterValues, currentFilters);
		setApplyDisabled(areFiltersChanged);
	}, [currentFilters, filterValues]);

	return (
		<div className="users-table-filters">
			<SearchFilter
				searchInputValue={currentFilters?.freeSearch}
				onInputChange={onInputChange}
				onEnter={(searchText: string) => onApply({ ...currentFilters, freeSearch: searchText })}
			/>
			<SelectFilter
				options={filterOptions?.userType}
				filterMultiselectId="user-type"
				placeHolder="Select User Type"
				allSelectedPlaceHolder="All User Types"
				someItemsSelectedText={(numberOfselected: number) => `User Types (${numberOfselected})`}
				values={currentFilters?.userType}
				filterType={FILTER_TYPES.USER_TYPE}
				onFilterChange={onFilterChange}
			/>
			<SelectFilter
				options={filterOptions?.active}
				filterMultiselectId="user-status"
				placeHolder="Select Status"
				allSelectedPlaceHolder="All Status"
				someItemsSelectedText={(numberOfselected: number) => `Statuses (${numberOfselected})`}
				values={currentFilters?.active}
				filterType={FILTER_TYPES.ACTIVE}
				onFilterChange={onFilterChange}
			/>
			<div className="filters-actions">
				<EvButton
					type={BUTTON_TYPES.ACTION}
					title="Apply filters"
					onClick={() => onApply(currentFilters)}
					disabled={disableButtons || applyDisabled}
				>
					Apply
				</EvButton>
				<EvButton
					type={BUTTON_TYPES.ACTION}
					title="Clear Filters"
					onClick={onClear}
					disabled={disableButtons || clearDisabled}
				>
					Clear
				</EvButton>
			</div>

			{renderSrSummary()}
		</div>
	);
};
export default UsersTableFilters;
