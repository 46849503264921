import React from 'react';
import ReactDOM from 'react-dom';

import analytics from './services/analytics/AnalyticsService';
import hubspotService from './services/analytics/HubspotService';
import AppContainer from './AppContainer';

import 'bootstrap/dist/css/bootstrap.css';
import './index.css';

// Init analytics
analytics.initAnalytics();

// Init hubspot
hubspotService.initHubspotClient();

// eslint-disable-next-line no-undef
console.log(`Current UI Version is: ${UI_VERSION}`);

const rootElement = document.getElementById('root');
if (!rootElement) {
	throw new Error('Root element not found');
}

ReactDOM.render(<AppContainer />, rootElement);
