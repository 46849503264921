import React, { FC } from 'react';
import { Route, Router, Switch, useHistory } from 'react-router';

import RoutesHelper from '../../helpers/RoutesHelper';
import EditUserPage from '../edit-user/EditUserPage';

import ManageUsersTabsView from './ManageUsersTabsView';

const ManageUsersRouter: FC = () => {
	const history = useHistory();
	return (
		<Router history={history}>
			<Switch>
				<Route exact path={RoutesHelper.getEditUserPath(':id')} component={EditUserPage} />
				<Route
					path={RoutesHelper.getManageUserTabsViewPagePath()}
					component={ManageUsersTabsView}
				/>
			</Switch>
		</Router>
	);
};

export default ManageUsersRouter;
