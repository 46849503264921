import { EvTableColumn } from '@evinced-private/ui-common';

import { formatDate } from '../components/common/helpers/DateFormatHelper';

/**
 * Function that just returns CSV lines separator.
 * Used by all <CSVLink> components
 * Vlad. 28/08/19
 */
const getCsvSeparator = (): string => ',';

const escapeCsvField = (field: string): string => {
	if (field) {
		return `${field}`.replace(/"/g, '""');
	}
	return '';
};

type CsvRow = {
	[key: string]: string;
};

type FieldValue = string | number;

const compareDates = (d1, d2): number => {
	const date1 = new Date(d1).getTime();
	const date2 = new Date(d2).getTime();

	if (date1 < date2) {
		return 1;
	}
	if (date1 > date2) {
		return -1;
	}
	return 0;
};

const compareValues = (firstValue: FieldValue, secondValue: FieldValue, isDate = false): number => {
	if (isDate) {
		return compareDates(firstValue, secondValue);
	}
	if (typeof firstValue === 'string' && secondValue === 'string') {
		return secondValue.localeCompare(firstValue);
	}

	/** Convert both values to numbers if possible */

	const firstNumber = Number(firstValue);
	const secondNumber = Number(secondValue);

	// Check if both values are valid numbers
	// eslint-disable-next-line no-restricted-globals
	if (!isNaN(firstNumber) && !isNaN(secondNumber)) {
		return firstNumber - secondNumber;
	}

	// Handle cases where one value is a string and the other is a number
	if (typeof firstValue === 'string') {
		return -1; // Treat the string as smaller
	}
	if (typeof secondValue === 'string') {
		return 1; // Treat the string as larger
	}

	// Handle other cases (e.g., non-numeric or non-string values)
	return 0;
};

export type CsvHeader = {
	label: string;
	key: string;
};

export const createHeadersOutOfTableColumns = (tableColumns: EvTableColumn[]): CsvHeader[] =>
	tableColumns.map((column) => ({ label: column.text, key: column.dataField }));

/**
 * This function receives array of issues, then creates custom data array that should
 * be exported to CSV.
 * @param {array} reportItems - array of report items that should be exported to CSV
 * Vlad. 28/08/19
 */
const getReportsCsvData = (
	reportItems,
	headers,
	sortField?: string,
	options?: { formatDate?: string[]; formatters? }
): CsvRow[] => {
	if (reportItems.length) {
		if (sortField) {
			reportItems.sort((a, b) =>
				compareValues(a[sortField], b[sortField], options?.formatDate?.includes(sortField))
			);
		} else {
			reportItems.sort((a, b) => b.pageUrl.localeCompare(a.pageUrl));
		}

		return reportItems.map((item) => {
			const csvData = headers.reduce((res, header) => {
				if (options?.formatDate?.includes(header.key)) {
					const rawDate = item[header.key];
					res[header.key] = escapeCsvField(rawDate ? formatDate(rawDate) : 'N/A');
				} else if (options?.formatters?.[header.key]) {
					res[header.key] = escapeCsvField(options.formatters[header.key](item));
				} else {
					res[header.key] = escapeCsvField(header.key);
				}
				return res;
			}, {});
			return csvData;
		});
	}
	return [];
};

export default {
	getCsvSeparator,
	getReportsCsvData,
	createHeadersOutOfTableColumns
};
