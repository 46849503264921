import { TOKEN_QUERY_PARAM } from './InvitationTokenHelper';

const SITE_SCANNER_LOGIN_URL = process.env.SITE_SCANNER_URL;
const getHomepagePath = (): string => {
	return '/';
};

const getFlowAnalyzerForMobilePath = (): string => {
	return '/mobile-flow-analyzer';
};

const getMobileSDKPath = (): string => {
	return '/mobile-sdk';
};

const getWebSDKPath = (): string => {
	return '/web-sdk';
};

const getLoginPath = (): string => {
	return '/login';
};

const getLoginSuccessPath = (): string => {
	return '/login-success';
};

const getSignupPath = (): string => {
	return '/signup';
};

const getSiteScannerPath = (): string => {
	return SITE_SCANNER_LOGIN_URL;
};

const getInvitationActivationPage = (invitationToken?: string): string => {
	return `/invitation-activation${
		invitationToken ? `?${TOKEN_QUERY_PARAM}=${invitationToken}` : ''
	}`;
};
const getNoTenantPath = (): string => {
	return '/no-tenant';
};

const getInvitationRedirectionPath = (): string => {
	return '/connect';
};

const getInvitationsPagePath = (): string => {
	return '/invitations';
};
const getUserManagementPagePath = (): string => {
	return '/manage-users/pending-invitations';
};
const getManageUserTabsViewPagePath = (): string => {
	return '/manage-users';
};

const getActiveUsers = (): string => {
	return '/manage-users/active-users';
};

const getEditUserPath = (id: string): string => {
	return `/manage-users/active-users/${id}`;
};

const getOpenInvitationRedirectionPath = (): string => {
	return '/open-connect';
};

const getTenantCreationPage = (): string => {
	return '/tenant-creation';
};

const getCodeAssistantPage = (): string => {
	return '/code-assistant';
};

const getUnauthorized403Page = (): string => {
	return '/unauthorized';
};

export default {
	getFlowAnalyzerForMobilePath,
	getInvitationActivationPage,
	getMobileSDKPath,
	getWebSDKPath,
	getLoginPath,
	getLoginSuccessPath,
	getSignupPath,
	getHomepagePath,
	getSiteScannerPath,
	getInvitationRedirectionPath,
	getInvitationsPagePath,
	getUserManagementPagePath,
	getManageUserTabsViewPagePath,
	getEditUserPath,
	getOpenInvitationRedirectionPath,
	getTenantCreationPage,
	getActiveUsers,
	getCodeAssistantPage,
	getUnauthorized403Page,
	getNoTenantPath
};
