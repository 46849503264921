import React, { FC } from 'react';

import { EvPopup, IEvPopupProps } from '@evinced-private/ui-common';

import { PRODUCT_HUB_APP_ID } from '../../../consts/dom-consts';

const ProductHubPopup: FC<IEvPopupProps> = (props) => {
	return <EvPopup appElement={PRODUCT_HUB_APP_ID} {...props} />;
};

export default ProductHubPopup;
