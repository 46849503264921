import React, { FC, useCallback, useEffect, useState } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';

import { withAuthenticationRequired } from '@auth0/auth0-react';

import { EvSpinner, PendoService, useTitle } from '@evinced-private/ui-common';

import AppHelper from 'src/helpers/AppHelper';

import { getErrorMessage } from '../../helpers/ApiErrorHelper';
import InvitationTokenHelper from '../../helpers/InvitationTokenHelper';
import PrivateRouteRedirectionHelper from '../../helpers/PrivateRouteRedirectionHelper';
import AuthenticationService from '../../services/AuthenticationService';
import Logger from '../../services/Logger';
import loginRedirectionService from '../../services/LoginRedirectionService';
import { hasTenant } from '../../services/TenantsService';
import userInvitationsService from '../../services/UserInvitationService';
import FullPageError from '../full-page-error/FullPageError';

const pendoApiKey = process.env.PENDO_API_KEY;

const getCurrentRelativeUrl = (): string => {
	let url = window.location.pathname;
	const params = window.location.search;
	const urlParams = new URLSearchParams(params);
	const searchParams = urlParams.toString();
	if (searchParams) {
		url += `?${searchParams}`;
	}
	return url;
};

const saveCurrentUrlForRedirection = async (): Promise<void> => {
	const redirectToUrl = getCurrentRelativeUrl();
	loginRedirectionService.setRedirectToPath(redirectToUrl);
};

const PrivateRoute: FC<RouteProps> = ({ component: RouteComponent, ...rest }: RouteProps) => {
	const [isLoading, setLoading] = useState(true);
	const [userHasTenant, setUserHasTenant] = useState(false);
	const [userHasPendingInvitations, setUserHasPendingInvitations] = useState(false);
	const [error, setError] = useState(null);
	const location = useLocation();
	const { setTitle } = useTitle();
	setTitle(AppHelper.getPageTitle(''));

	const checkForUserTenantAndInvitations = useCallback(async () => {
		// short circuit - no need to check if there's a tenant on each route if we know they have
		if (userHasTenant) {
			return;
		}
		setLoading(true);
		try {
			const userHasTenant = await hasTenant();
			setUserHasTenant(userHasTenant);
			const pendingInvitations = await userInvitationsService.getAllUserPendingInvitations();
			const openInvitations = await userInvitationsService.getOpenInvitationsDetails();
			setUserHasPendingInvitations(pendingInvitations.length > 0 || openInvitations?.length > 0);
		} catch (error) {
			Logger.error('Error getting tenant or invitations', error);
			setError(
				`An error occurred while retrieving your account information. ${getErrorMessage(error)}`
			);
		} finally {
			setLoading(false);
		}
	}, [userHasTenant]);

	useEffect(() => {
		checkForUserTenantAndInvitations();
	}, [location, checkForUserTenantAndInvitations]);

	if (isLoading) {
		return <EvSpinner />;
	}
	if (error) {
		return <FullPageError title="Product Loading Error" />;
	}
	PendoService.init(
		{
			visitorId: AuthenticationService.getEvincedUserId(),
			email: AuthenticationService.getUserEmail(),
			accountId: AuthenticationService.getUserAccountId()
		},
		pendoApiKey,
		Logger
	);
	return (
		<Route
			{...rest}
			render={(props) => {
				if (!userHasTenant) {
					const { pathname } = location;
					const redirectTo = PrivateRouteRedirectionHelper.getRedirectToUrl(
						pathname,
						userHasPendingInvitations
					);
					// no need for redirect if the user is already in that path
					if (pathname !== redirectTo) {
						return (
							<Redirect
								to={{
									pathname: redirectTo,
									state: { from: props.location },
									search: props.location.search
								}}
							/>
						);
					}
				}
				return <RouteComponent {...props} />;
			}}
		/>
	);
};

export default withAuthenticationRequired(PrivateRoute, {
	onBeforeAuthentication: saveCurrentUrlForRedirection,
	onRedirecting: () => {
		return <EvSpinner />;
	},
	loginOptions: {
		// passing this parameter to the signup screen so it will no show the signup option
		appState: { 'ext-hidesignup': InvitationTokenHelper.shouldHideSignupOption() }
	}
	// eslint-disable-next-line no-mixed-spaces-and-tabs
});
