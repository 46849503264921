export enum USER_STATUS {
	ACTIVE = 'Active',
	INACTIVE = 'Inactive'
}

export type RoleTypeItem = {
	id: string;
	name: string;
	type: string;
};
export interface IUserToTenant {
	id: string;
	createdTime: Date | string;
	active: boolean;
	user: IUserInfo;
	tenantId: string;
	role: RoleTypeItem;
}
export interface IUser {
	id: string;
	name: string;
	surname: string;
	email: string;
	role: {
		id?: string;
		name: string;
		type: string;
	};
	isSSO?: boolean;
}

interface IUserInfo {
	id: string;
	name: string;
	surname: string;
	email: string;
	lastLogin?: string;
}
