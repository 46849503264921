import React from 'react';

import { EvIcon } from '@evinced-private/ui-common';

import NotFoundIcon from '../../components/icons/NotFoundIcon.svg';
import RoutesHelper from '../../helpers/RoutesHelper';

import './NotFound404Page.scss';

export default (): JSX.Element => {
	return (
		<div className="error-404">
			<div className="error-404-content">
				<EvIcon icon={NotFoundIcon} />
				<div className="new-text-message">
					<p>Sorry, we couldn&apos;t find what you were looking for...</p>
					<a href={RoutesHelper.getHomepagePath()} className="back">
						Back to Home page
					</a>
				</div>
			</div>
		</div>
	);
};
