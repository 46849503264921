import {
	navigationBreadcrumbsToFilter,
	navigationTitleMapping
} from '../components/header-navigation-breadcrumbs/HeaderNavigationConsts';

export const getPathNavigationLabel = (string: string): string => {
	if (
		navigationBreadcrumbsToFilter.includes(string) ||
		navigationBreadcrumbsToFilter.includes(`/${string}`)
	) {
		return '';
	}

	if (navigationTitleMapping[string]) {
		return navigationTitleMapping[string];
	}
	const words = string.split('-');

	// Capitalize each word and join them together
	const label = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

	return label;
};
