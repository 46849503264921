import React, { FC, useMemo } from 'react';

import classNames from 'classnames';

import {
	BUTTON_TYPES,
	EvContactUsButton,
	EvRadioGroup,
	EvStep,
	EvTitle
} from '@evinced-private/ui-common';

import { useUserTenant } from '../../../providers/userTenantProvider/UserTenantProvider';
import { hubspotAPIKey } from '../../../services/analytics/HubspotService';

import './Steps.scss';

interface ISetPermissions {
	handleChange: (res: string) => void;
	userPermission: string;
	onContinue?: () => void;
	isStepper?: boolean;
	userEmail?: string;
	className?: string;
}

const selectionTitle = 'Select a user type:';

const optionComponent = (optionLabel: string, optionToolTip: string): JSX.Element => (
	<div className="option-component">
		<div className="option-label">{optionLabel}</div>
		<div className="option-explain">{optionToolTip}</div>
	</div>
);

const SetPermissions: FC<ISetPermissions> = ({
	handleChange,
	onContinue,
	userPermission,
	isStepper = true,
	userEmail = '',
	className
}) => {
	const { usersRolesListDictionary, getFullRoleItem, user, tenant } = useUserTenant();

	const roleType = usersRolesListDictionary.TENANT_USER;
	const additionalInfo = {
		isRequired: true,
		label: `Please help update the type of user associated with this email address ${userEmail}`
	};

	const renderPermissionsSelection = useMemo((): JSX.Element => {
		const radioGroupOptions = [
			{
				label: getFullRoleItem(roleType).name,
				component: optionComponent(
					getFullRoleItem(roleType).name,
					'Can crawl, scan and use all Site Scanner features.'
				),
				topAlignRadioWithLabel: true,
				isChecked: userPermission === roleType,
				onClick: () => handleChange(roleType)
			},
			{
				label: getFullRoleItem(usersRolesListDictionary.READ_ONLY).name,
				component: optionComponent(
					getFullRoleItem(usersRolesListDictionary.READ_ONLY).name,
					"Can view issues, can't take actions like crawl or scan."
				),
				topAlignRadioWithLabel: true,
				isChecked: userPermission === usersRolesListDictionary.READ_ONLY,
				onClick: () => handleChange(usersRolesListDictionary.READ_ONLY)
			}
		];
		return (
			<EvRadioGroup
				options={radioGroupOptions}
				name="user-type-radio"
				className="permissions-options-container"
				ariaLabel="User type selection"
			/>
		);
	}, [usersRolesListDictionary, roleType, userPermission, handleChange, getFullRoleItem]);

	const renderAdminMessage = (): JSX.Element => {
		return (
			<div className="admin-message">
				<p>
					This user is set as Admin. In order to change the user type, please contact our customer
					support team.
				</p>
				<EvContactUsButton
					userEmail={user.email}
					userTenant={tenant?.id}
					type={BUTTON_TYPES.ACTION}
					portalId={hubspotAPIKey}
					formTitle="How can we help?"
					product="Account settings / User management"
					additionalInfo={additionalInfo}
				/>
			</div>
		);
	};

	const setPermissionContent = (): JSX.Element => {
		return (
			<div className="permission-selection-wrapper">
				{userPermission === usersRolesListDictionary.TENANT_ADMIN ? (
					<div>{renderAdminMessage()}</div>
				) : (
					<div className="permission-selection-not-admin">
						{!isStepper && <div className="selection-instructions">{selectionTitle}</div>}
						{renderPermissionsSelection}
					</div>
				)}
			</div>
		);
	};

	if (isStepper) {
		return (
			<EvStep actions={{ continueLabel: 'Send Invite(s)', onNextClicked: onContinue }}>
				<div className="selection-instructions">{selectionTitle}</div>
				{setPermissionContent()}
			</EvStep>
		);
	}

	return (
		<div className={classNames('set-permissions', className)}>
			<EvTitle titleText="Site Scanner permissions" />
			{setPermissionContent()}
		</div>
	);
};

export default SetPermissions;
