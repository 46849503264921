import React, { SFC } from 'react';

import { EVINCED_SUPPORT_EMAIL, EvPopup } from '@evinced-private/ui-common';

import { PRODUCT_HUB_APP_ID } from '../../../consts/dom-consts';
import { getErrorMessage, isClientError } from '../../../helpers/ApiErrorHelper';
import Logger from '../../../services/Logger';
import { IServerError, IServerErrors } from '../../../services/server/ServerError';
import { ContactSupportMailLink } from '../../contact-support/ContactSupportMailLink';

import './AlertPopup.scss';

export interface IAlertPopup {
	/**
	 * Error message to display
	 */
	errorMessage?: string;

	/**
	 * popup's title
	 */
	title?: string;

	/**
	 * Server error object
	 */
	serverError?: IServerError;

	/**
	 * List of Server error object
	 */
	serverErrors?: IServerErrors[];

	/** popup is shown only if isOpened==true */
	isOpened?: boolean;

	/**
	 * A callback function to be called when user hits OK to close the popup.
	 * The function will be called after the popup is closed.
	 */
	onOK?: () => void | Promise<void>;
}

const AlertPopup: SFC<IAlertPopup> = ({
	title = 'Something went wrong',
	errorMessage,
	onOK,
	serverError,
	serverErrors,
	isOpened = false
}: IAlertPopup) => {
	const buttons = [
		{
			title: 'OK',
			onClick: () => {
				Logger.info('Error popup closed on OK click');
				onOK();
			}
		}
	];

	const renderErrorTitle = (): JSX.Element => <div className="error-title">{title}</div>;

	const renderErrorMessage = (): JSX.Element => {
		if (!errorMessage) {
			return null;
		}
		return <div className="error-message">{errorMessage}</div>;
	};

	const renderSupportEmailMsg = (serverErrorMsg: string): JSX.Element => {
		const splitMessage = serverErrorMsg.split(EVINCED_SUPPORT_EMAIL);
		return (
			<div className="error-details">
				{splitMessage[0] || ''}
				<ContactSupportMailLink />
				{splitMessage[1] || ''}
			</div>
		);
	};

	const renderErrMsg = (serverErrorMsg: string): JSX.Element => (
		<div className="error-details">{serverErrorMsg}</div>
	);

	const renderServerErrorsDetails = (): JSX.Element => {
		const serverErrorMessages = [];
		serverErrors.forEach((serverErr) => {
			const serverErrorMessage = getErrorMessage(serverErr.serverError);
			serverErrorMessages.push(`${serverErr.prefixMsg} ${serverErrorMessage}`);
		});
		return (
			<>
				{serverErrorMessages
					.filter((msg) => msg)
					.map((serverErrorMsg) => {
						if (serverErrorMsg.includes(EVINCED_SUPPORT_EMAIL)) {
							return renderSupportEmailMsg(serverErrorMsg);
						}
						return renderErrMsg(serverErrorMsg);
					})}
			</>
		);
	};

	const renderServerErrorDetails = (): JSX.Element => {
		const serverErrorMessage = getErrorMessage(serverError);
		if (!serverErrorMessage) {
			return null;
		}

		if (serverErrorMessage.includes(EVINCED_SUPPORT_EMAIL)) {
			return renderSupportEmailMsg(serverErrorMessage);
		}
		return renderErrMsg(serverErrorMessage);
	};

	const isErrorFromClient = isClientError(serverError);

	return (
		<EvPopup
			appElement={PRODUCT_HUB_APP_ID}
			title={title}
			isSrOnly={!isErrorFromClient}
			buttons={buttons}
			isControlled
			isCentered
			isOpen={isOpened}
			className="error-popup"
			onClose={onOK}
		>
			<div className="alert-popup">
				{!isErrorFromClient && renderErrorTitle()}
				{renderErrorMessage()}
				{serverErrors ? renderServerErrorsDetails() : renderServerErrorDetails()}
			</div>
		</EvPopup>
	);
};

export default AlertPopup;
