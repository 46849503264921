import React from 'react';

import { IEvConfirmProps, TOAST_STATUS } from '@evinced-private/ui-common';

import { IAlertPopup } from '../../components/common/alert-popup/AlertPopup';

export interface ILocalEvToastProps {
	announcement?: string | JSX.Element;
	status?: TOAST_STATUS;
	show?: boolean;
	onToastClose?: () => void;
}

export type INotificationsContext = {
	alert?: (errorDetails: IAlertPopup) => void;
	toast?: (toastDetails: ILocalEvToastProps) => void;
	confirm?: (confirmDetails: IEvConfirmProps) => void;
};

const NotificationsContext = React.createContext<Partial<INotificationsContext>>({});

export default NotificationsContext;
